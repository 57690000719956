<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader> Configuración </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6">
              <CInput
                type="time"
                label="Hora de asignación de medallas"
                placeholder="00:00"
                v-model="form.badge_award_time"
                required
              /> 
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="0"
                max="100"
                label="Retención IVA"
                placeholder="0.00"
                v-model="form.retencionIva"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="percent"
                /></template>
              </CInput>
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="0"
                max="100"
                label="Retención Renta"
                placeholder="0.00"
                v-model="form.retencionRenta"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="percent"
                /></template>
              </CInput>
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                label="Comisión Distribuidor"
                placeholder="0.00"
                v-model="form.commission_distrib"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="percent"
                /></template>
              </CInput> 
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                label="Comisión Mayorista"
                placeholder="0.00"
                v-model="form.commission_vendor"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="percent"
                /></template>
              </CInput> 
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="0"
                max="100"
                label="Porcentaje descuento máximo"
                placeholder="0.00"
                v-model="form.discount"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="percent"
                /></template>
              </CInput>
            </CCol>
            
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="0"
                max="1"
                step="0.01"
                label="Coeficiente de costo"
                placeholder="0.00"
                v-model="form.coefficient_cost"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="hashtag"
                /></template>
              </CInput>
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="0"
                max="1"
                step="0.01"
                label="Margen de distribuidor"
                placeholder="0.00"
                v-model="form.margin_distributor"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="hashtag"
                /></template>
              </CInput>
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="0"
                max="1"
                step="0.01"
                label="Margen general"
                placeholder="0.00"
                v-model="form.margin_general"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="hashtag"
                /></template>
              </CInput>
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="0"
                max="10"
                step="1"
                label="Máxima cantidad en enlaces"
                placeholder="0"
                v-model="form.max_qty"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="hashtag"
                /></template>
              </CInput>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow class="mt-2">
            <CCol sm="12" md="6">
              <CButton color="primary" @click="goBack">Back</CButton>
            </CCol>
            <CCol sm="12" md="6" class="text-right">
              <CButton color="primary" @click="save" v-if="!loading"
                >Guardar</CButton
              >
              <div class="text-right" v-if="loading">
                <CSpinner variant="grow" size="lg" />
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import moment from "moment";

const resource = new Resource("config");

export default {
  name: "Config",
  data() {
    return {
      loading: false,
      toasts: [],
      form: {
        id: null,
        badge_award_time: null,
        discount: 0.0,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async save() {
      this.loading = true;
      try {
        const response = await resource.store(this.form);
        if (response.status == 200 && response.data.status == "OK") {
          const { created_at, updated_at, ...data } = response.data.data;
          this.form = data;
          this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Configuración actualizada con exito",
          });
        } else {
          throw response.data.message;
        }
      } catch (error) {
        console.log(error);
        this.toasts.push({
          title: "Error",
          color: "danger",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },

    async loadData() {
      this.loading = true;
      try {
        const response = await resource.list();
        if (response.status == 200) {
          const { data } = response.data;
          if (data) {
            const { created_at, updated_at, ...form } = data;
            this.form = form;
          }
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
